import React from 'react'
import Card from 'react-bootstrap/Card'
import Button from 'react-bootstrap/Button'
import CardDeck from 'react-bootstrap/CardDeck'
import { Link } from 'gatsby'
import { Fade } from 'react-awesome-reveal'
import Img from 'gatsby-image'

const ArticleGrid = props => {
  return (
    <Fade>
      <CardDeck className=" pt-5">
        {props.data.map((element, index) => (
          <Card bg="light" className="mt-5" border="dark">
            <Card.Img as={Img}
              fluid={{...element.node.frontmatter.thumbnail.childImageSharp.fluid,aspectRatio: 16/9}}
              alt="error"
            />
            <Card.Body>
              <Card.Title>{element.node.frontmatter.title}</Card.Title>
              <Card.Text>{element.node.excerpt}</Card.Text>
            </Card.Body>
            <Card.Footer>
              <Link to={element.node.fields.slug}>
                <Button variant="info" size="sm" className="mr-3">
                  Lire l'article
                </Button>
              </Link>
              <small className="text-muted">
                Créé le{' '}
                {new Date(
                  Date.parse(element.node.frontmatter.date)
                ).toLocaleDateString()}
              </small>
            </Card.Footer>
          </Card>
        ))}
      </CardDeck>

    </Fade>
  )
}

export default ArticleGrid
