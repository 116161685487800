import React, { Component } from 'react'
import Layout from '../components/layout/Layout'

import { graphql } from 'gatsby'
import ArticleGrid from '../components/common/ArticleGrid'

class App extends Component {
  render() {
    return (
      <>
        <Layout backgroundImage>
          <ArticleGrid data={this.props.data.allMarkdownRemark.edges} />
        </Layout>
      </>
    )
  }
}
// In your index.js file
// <ArticleGrid data={this.props.data.allMarkdownRemark.edges} />

export const query = graphql`
  {
    allMarkdownRemark(filter: {fileAbsolutePath: {regex: "/(news)/"  }}) {
      edges {
        node {
          fields {
            slug
          }
          excerpt
          html
          frontmatter {
            date
            layout
            title
            thumbnail {
              childImageSharp {
                fluid(quality:79){
                  # Choose either the fragment including a small base64ed image, a traced placeholder SVG, or one without.
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
      }
    }
  }
`

export default App
